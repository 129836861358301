.App {
  height: 100vh;
}

/* initial loading */
.full-page-loader {
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}

.page-content.entry-content pre {
  white-space: normal !important;
  }

.clear {
  clear: both;
}

.nav-link {
  cursor: pointer !important;
}

/* --- HOME --- */

.nameBanner {
  position: relative;
  top: 25%;
}

span.homeName {
  color: rgb(237, 234, 229);
  font-family: 'Berkshire Swash', cursive;
  font-size: 3rem;
}

/*  --- MAIN CONTAINER AND SAFARI SPECIFIC QUERIES --- */

.content{
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  flex-flow: column wrap;
  -webkit-flex-flow: column wrap;
  width: 75%;
}

/* account for safari stretching of row */
@media screen and (min-color-index:0) and(-webkit-min-device-pixel-ratio:0) {
    @media {
      .safari6 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: column nowrap;
        width: 50%;
    }
}
}

/* account for safari stretching of row */
@media not all and (min-resolution:.001dpcm) {
  @media {
      .safari10 {
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-flow: column nowrap;
        width: 50%;
      }
  }
}

.row:before,
.row:after {
  display: none !important;
}

/* ---HOME SECTION GRAPHIC--- */

.headshotCol {
  padding-right: 0px;
}

.headshot {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.me {
  border: 2px solid rgb(237, 234, 229);
  border-radius: 50%;
  height: 100%;
  width: 100%;
}

/* --- SOCIAL MEDIA ICONS --- */

.name {
  height: 100%;
  font-size: 1.5em;
  display: flex;
  align-items: center;
}

.socialsCol {
  padding-left: 0px;
}

.socials {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  align-items: center;
}

.socialsContact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  align-items: center;
}

span.divider {
  flex: 1;
  border-bottom: 2px solid rgb(237, 234, 229);
  margin: 0px
}

span.spacer {
  margin: 2%;
}

.socials, a:link,
.socialsContact, a:link {
  color: rgb(237, 234, 229);
}
.socials, a:visited,
.socialsContact, a:visited{
  color: rgb(237, 234, 229);
}
.socials, a:hover,
.socialsContact, a:hover{
  color: rgb(237, 234, 229);
}
.socials, a:focus,
.socialsContact, a:focus{
  color: rgb(237, 234, 229);
}
.socials, a:active,
.socialsContact, a:active{
  color: rgb(237, 234, 229);
}

/* ---NAV BAR--- */

.navBar {
  background: rgb(248, 112, 87);
  font-size: 1.25rem;
}

.navBar .nav-link {
  color: rgb(237, 234, 229) !important;
}

.activeLink {
  border-bottom: 1px solid rgb(237, 234, 229);
}

/* --- PAGE SECTIONS --- */

#home {
  background-color: rgb(248, 112, 87);
  color: rgb(45,47,49);
  height: 100vh;
  margin: 0 auto;
  overflow: hidden;
}

#about {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(237, 234, 229);
  color: rgb(45,47,49);
  min-height: 65vh;
  margin: 0 auto;
  padding-top: 5%;
}

#contact {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(248, 112, 87);
  color: rgb(237, 234, 229);
  height: 20vh;
  margin: 0 auto;
}

#projects {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  background-color: rgb(237, 234, 229);
  color: rgb(45,47,49);
  min-height: 65vh;
  margin: 0 auto;
  padding-bottom: 2%;
}

.sectionTitle {
  font-size: 3em;
  font-weight: bold;
}

.headerTitle {
  font-weight: bold;
}

.navLink {
  font-weight: bold;
}

/* --- PROJECTS --- */

.projectBox {
  background: rgb(45,47,49);
  color: rgb(237, 234, 229);
  border: 1px solid black;
  border-radius: 3.5px;
  height: 200px;
  padding: 2.5%;
}

.contactBlock {
  text-align: center;
}

.jumpToTop {
  position: fixed;
  bottom: 4;
  z-index: 1;
}

/* ---PROJECT CARDS--- */

.projectCard * {
  transform: scale(1.01);
}

.card {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-shadow: 0 10px 10px -10px black;
	   -moz-box-shadow: 0 10px 10px -10px black;
          box-shadow: 0 10px 10px -10px black;
  margin: 10px;
}

.card-img-top {
  min-height: 40%;
  max-height: 40%;
  max-width: 100%;
  object-fit: cover;
}

.card-title {
  font-size: 2em;
  font-weight: bold;
}

.card-body {
  background: rgb(45, 47, 49);
  color: rgb(237, 234, 229);
  text-align: center;
  font-size: .8rem;
}

.card .languages{
  font-weight: bold;
}

.card-footer {
  background: rgb(45, 47, 49);
  color: rgb(237, 234, 229);
  text-align: center;
  border-top: 0 !important;
}

.herokuLink {
  float: left;
}

.codeLink {
  float: right;
}

.aboutIcons svg {
  margin: 1%;
}

.miniEmail {
    display: none;
}

.card-footer, a:link {
  color: rgb(237, 234, 229) !important;
}

.card-footer, a:visited{
  color: rgb(237, 234, 229) !important;
}

.card-footer, a:hover{
  color: rgb(237, 234, 229) !important;
}

.card-footer, a:focus{
  color: rgb(237, 234, 229) !important;
}

.card-footer, a:active{
  color: rgb(237, 234, 229) !important;
}

/* ---BREAK POINTS--- */

@media (max-width: 805px) {
  #contact {
    padding-top: 1%;
    min-height: 15vh;
  }

  .contactTitle {
    display: none;
  }

  .location {
    display: none;
  }
}

@media (max-width: 767px) {
  .card {
    width: 17rem !important;
  }
}

@media (max-width: 576px)  {
  #contact {
    padding-top: 1%;
    min-height: 15vh;
  }

  .contactTitle {
    display: none;
  }

  .location {
    display: none;
  }
}

@media (max-width: 415px) {
  .navBar {
    font-size: 1rem;
  }

  .content {
    width: 90%;
  }

  #about .aboutIcon {
    height: 2em;
    width: 2em;
  }
}

@media (max-width: 400px)  {
  #contact {
    padding-top: 1%;
    min-height: 15vh;
  }

  .contactBlock {
    padding: 0%;
  }

  .contactTitle {
    display: none;
  }

  .location {
    display: none;
  }

  .normalEmail {
    display: none;
  }
  .miniEmail {
    display: block;
  }

  .socialBlock {
    padding-right: 5%;
  }

}
